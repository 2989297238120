import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Lottie from 'lottie-react';
import animationData from '../assets/animations/messageAnimation.json';
import emailjs from 'emailjs-com';

export default function AppContact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [showPopup, setShowPopup] = useState(false); // Estado para mostrar el pop-up

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_p0u92ul', 'template_hwmfzpp', e.target, 'VcikM84mp4jSI47C8') // Reemplazar con tus IDs
      .then((result) => {
        console.log(result.text);
        // Limpiar el formulario después de enviar
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        // Mostrar el pop-up
        setShowPopup(true);
      }, (error) => {
        console.log(error.text);
        // Manejar el error aquí si es necesario
      });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section id='contact' className='block contact-block'>
      <Container fluid>
        <div className='title-holder'>
          <h1>Contact Us!</h1>
          <div className='subtitle'>We will be happy to help you.</div>
        </div>
        <Row>
          <Col sm={6}>
            <Lottie animationData={animationData} />
          </Col>
          <Col sm={6}>
            <Form className='contact-form' onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <Form.Control type='text' name='name' value={formData.name} placeholder="Enter your full name" required onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Control type='email' name='email' value={formData.email} placeholder="Enter your email address" required onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Control type='tel' name='phone' value={formData.phone} placeholder="Enter your contact number" required onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Control as="textarea" name='message' value={formData.message} placeholder='Enter your message' required onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} className='btn-holder'>
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* Popup */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <h2>Email Sent!</h2>
            <p>Our team will be contacting you.</p>
            <p>Thank you very much!</p>
            <Button onClick={handleClosePopup}>Ok</Button>
          </div>
        </div>
      )}
    </section>
  );
}
