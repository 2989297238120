import React, {} from 'react';
import Container from 'react-bootstrap/Container';

const AppFormContact = () => {
  return (
    <section id='quote' className='block contact-block'>
      <Container fluid>
        <div className='title-holder'>
          <h1>Do you want to request a quote for a project?</h1>
          <div className='subtitle'>Our team will be delighted to review the information and contact you as soon as possible</div>
          <p>Please, <a href='https://docs.google.com/forms/d/e/1FAIpQLSelQKdrjs30kbVRd-x0hxt-gqQTPbUptcINF6VzdltSL6254Q/viewform?usp=pp_url' target='_blank'>click here</a></p>
        </div>
      </Container>
    </section>
  );
};

export default AppFormContact;
