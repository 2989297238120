import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/1.png'),
    title: 'Your Vision Becomes Our Mission',
    description: 'At DMS, every client is our partner in the journey to create something extraordinary. We are here to turn your ideas into stunning realities, combining our expertise and craftsmanship with your unique vision.',
  },
  {
    id: 2,
    image: require('../assets/images/2.png'),
    title: 'Intelligent and Effective Design',
    description: 'Turn your ideas into tangible projects with our guidance and the use of the most suitable tools for your needs. Experience greater precision, coordination, and efficiency at every stage of your project.',
  },
  {
    id: 3,
    image: require('../assets/images/3.png'),
    title: 'Tailored Solutions',
    description: 'We offer customized solutions for each project, from multidisciplinary modeling in the AEC sector, coordination and generation of detailed plans and 3D visualization.',
  }, 
  {
    id: 4,
    image: require('../assets/images/4.png'),
    title: 'Cutting-Edge Technology',
    description: 'We implement the latest technologies and BIM methodologies to optimize workflow, reduce costs, and improve the quality of your project.',
  },
  {
    id: 5,
    image: require('../assets/images/5.png'),
    title: 'Expert and Passionate Team',
    description: 'Our team of highly qualified professionals is dedicated to providing exceptional service and high-quality results.',
  },
  {
    id: 6,
    image: require('../assets/images/img10.jpg'),
    title: 'A Collaborative Approach',
    description: 'We work closely with our clients to ensure effective communication and complete satisfaction with the final project.',
  },
]

function AppHero() {
  return (
    <section id="home" className="hero-block" style={{ minHeight: '110vh' }}>
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100 full-height-image"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;
