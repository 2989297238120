//import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';

//export default function AppHeader(){
  //return(
      //<Navbar expand="lg" className="bg-body-tertiary">
      //<Container>
        //<Navbar.Brand href="#home">Zenlest Arquitectos e Ingenieros</Navbar.Brand>
        //<Navbar.Toggle aria-controls="basic-navbar-nav" />
        //<Navbar.Collapse id="basic-navbar-nav">
          //<Nav className="me-auto">
            //<Nav.Link href="#home">Inicio</Nav.Link>
            //<Nav.Link href="#about">Acerca</Nav.Link>
            //<Nav.Link href="#services">Servicios</Nav.Link>
            //<Nav.Link href="#works">Proyectos</Nav.Link>
            //<Nav.Link href="#testimonials">Referencias</Nav.Link>
            //<Nav.Link href="#blog">Blog</Nav.Link>
            //<Nav.Link href="#contact">Contacto</Nav.Link>
          //</Nav>
        //</Navbar.Collapse>
      //</Container>
    //</Navbar>
//)
//}
import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LogoImage from '../assets/images/LOGO DMS 155X120.png' 

function AppHeader() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar bg="light" expand="lg"  expanded={expanded} collapseOnSelect onSelect = {() => setExpanded(false)}>
      <Container>
        {/* Usa una etiqueta img para mostrar la imagen del logo */}
        <Navbar.Brand href="#home" className='logo-container'>
          <img
            src={LogoImage} // Ruta de la imagen del logo
            height="30" // Altura del logo (ajusta según sea necesario)
            className="d-inline-block align-top" // Clase de Bootstrap para alinear verticalmente
            alt="Logo" // Texto alternativo del logo para accesibilidad
          />
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          className='ml-auto' // Mueve el boton del lado derecho
          onClick = {() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="text-white"  href="#home" eventKey="1">Home</Nav.Link>
            <Nav.Link className="text-white"  href="#about" eventKey="2">About</Nav.Link>
            <Nav.Link className="text-white"  href="#services" eventKey="3">Services</Nav.Link>
            <Nav.Link className="text-white"  href="#contact" eventKey="7">Contact</Nav.Link>
            <Nav.Link className="text-white"  href="#quote" eventKey="6">Quote</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;



