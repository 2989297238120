import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function AppContactSocials(){
  return(

    <section className='block contact-block'> 
          <Container fluid>
        <Row>
        <div className='contact-info'>
          <ul>
            <li>
              <i className='fas fa-envelope envelope-icon'></i>
              <a href='mailto:info@dmstudiocr.com' className='footer-href'>info@dmstudiocr.com</a>
            </li>
            <li>
              <i className='fas fa-phone phone-icon'></i>
              <a href='tel:+50661948689'>6194-8689</a>
            </li>
            <li>
              <i className='fas fa-map-marker-alt marker-icon'></i>
              San José, Costa Rica
            </li>
          </ul>
        </div>
          </Row>
      </Container>
    </section>

  )
}
