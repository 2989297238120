import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function AppFooter() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <Container fluid>
      <div className='socials-footer'>
        <a href="https://www.facebook.com/dmsbim?mibextid=ZbWKwL" target='_blank'><FontAwesomeIcon icon={faFacebook} className="social-icon" /></a>
        <a href="https://www.linkedin.com/company/dms-bim-studio/about/" target='_blank'><FontAwesomeIcon icon={faLinkedin} className="social-icon" /></a>
        <a href="https://www.instagram.com/dms.cr?igsh=MWptaW9ucnE1dDE4ZA==" target='_blank'><FontAwesomeIcon icon={faInstagram} className="social-icon" /></a>
        <a href="https://wa.me/50661948689" target='_blank'><FontAwesomeIcon icon={faWhatsapp} className="social-icon" /></a>
      </div>
      <div className='copyright'>&copy; 2024 DMS BIM STUDIO. All Right Reserved.</div>
      {
        showTopBtn && (<div className='go-top' onClick={goTop}>↑</div>)
      }
    </Container>
  );
}
