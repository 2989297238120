const servicesData = [
  {
    id: 1,
    icon: 'fas fa-sitemap',
    title: 'BIM Coordination',
    description: 'We understand the importance of smooth and efficient coordination in BIM projects across disciplines. Our team of Revit modeling experts specializes in providing accurate and coordinated solutions that integrate all aspects of design and construction. With a meticulous approach and unwavering attention to detail, we facilitate harmonious collaboration that optimizes workflow and minimizes clashes.'
  },
  {
    id: 2,
    icon: 'fas fa-cube',
    title: '3D Modeling',
    description: 'We are proud to offer Revit, Civil Cad, and SketchUp modeling services that are designed to exceed the most demanding expectations. Our team of experts combines exceptional technical skills with a passion for precision and creativity. We are committed to delivering impeccable results that transform your vision into reality. We strive to offer solutions that are both functional and aesthetically stunning.'
  },
  {
    id: 3,
    icon: 'fas fa-pencil-ruler',
    title: '2D Drafting in AutoCAD',
    description: 'We excel in providing 2D AutoCAD drafting services that reflect our dedication to excellence and precision. Our team of talented professionals combines superior technical skills with an unwavering commitment to quality and attention to detail. From crafting architectural plans to creating detailed engineering drawings, we strive to deliver impeccable results that exceed our clients expectations.'
  },
  {
    id: 4,
    icon: 'fas fa-lightbulb',
    title: 'Dialux Photometric Studies',
    description: 'We specialize in providing Dialux photometric studies services for lighting designs that reflect our commitment to excellence and innovation in the design of light-filled spaces. Our team of lighting experts combines solid technical experience with a passion for creating captivating and inspiring environments.'
  },
  {
    id: 5,
    icon: 'fas fa-images',
    title: '3D Rendering Services',
    description: 'We take pride in offering a rendering service that combines technical excellence with exceptional artistic vision. Our team of Revit modeling experts is dedicated to transforming your designs into stunning and realistic visual representations. Each project is approached with meticulousness and attention to detail to ensure that every image faithfully reflects your creative vision.'
  },
  {
    id: 6,
    icon: 'fas fa-map-marker-alt',
    title: 'On-Site Survey',
    description: 'We understand the importance of understanding every detail of the environment to create exceptional designs. Our site survey service is the cornerstone of this process, where our team of experts immerses themselves in your space to capture every aspect with precision and meticulousness. Using cutting-edge technology and advanced techniques, we ensure that every dimension, shape, and feature is accurately recorded.'
  },
  {
    id: 7,
    icon: 'fas fa-cloud',
    title: 'Point Cloud Model',
    description: 'We are proud to offer point cloud data capture services that provide a solid foundation for the creation of accurate and detailed virtual models in Revit. Our team of experts utilizes cutting-edge technology to scan the environment and collect three-dimensional data with exceptional precision. This data is skillfully transformed into point clouds, which are then seamlessly integrated into our Revit modeling processes.'
  },
  {
    id: 8,
    icon: 'fas fa-file-archive',
    title: 'As-Built Drawings',
    description: "We understand the importance of accuracy and attention to detail in project documentation. Our As-Built Drawings service is dedicated to providing precise and up-to-date representations of existing structures. Our team of Revit modelers is committed to capturing every aspect of your building with meticulousness and professionalism, ensuring that the drawings accurately reflect the site's reality. Whether for renovations, maintenance, or future reference purposes, our As-Built Drawings are an invaluable tool for informed decision-making and efficient planning."
  },
  {
    id: 9,
    icon: 'fas fa-cogs',
    title: 'BIM Management',
    description: 'We specialize in offering BIM management services that stand out for their comprehensive approach and their ability to optimize each stage of the construction process. Our team of Revit modeling experts is responsible for coordinating and supervising all aspects related to the modeling and implementation of BIM technology in your project. From initial planning to final delivery, we are committed to ensuring impeccable and efficient execution of your project, maximizing collaboration between teams and minimizing associated risks.'
  },
  {
    id: 10,
    icon: 'fas fa-project-diagram',
    title: 'BIM Implementation',
    description: 'DMS stands out as a leading entity in the Revit Modeling industry, offering an unparalleled BIM Implementation service. With an unmatched dedication to excellence and precision, our company is committed to providing BIM solutions that completely transform the way construction projects are conceived and executed. From initial planning to final delivery, our team of experts immerses themselves in every detail, ensuring a smooth and efficient integration of the BIM model throughout the entire process. With DMS, BIM implementation is not just a technological solution, it is a symphony of innovation, professionalism, and exceptional results'
  },
  {
    id: 11,
    icon: 'fas fa-camera-retro',
    title: 'Photogrammetry',
    description: 'We are proud to offer state-of-the-art photogrammetry services, adding a touch of precision and perfection to every project. Our team of experts combines cutting-edge technology with a meticulous artisan approach to capture and process data with unmatched accuracy. From creating detailed 3D models to generating topographic maps and orthophotos, our photogrammetry redefines the standards of quality and accuracy. With DMS, every detail comes to life with crystal-clear clarity, providing unparalleled insight and unlimited opportunities for innovation and success at every stage of the process.'
  },
  {
    id: 12,
    icon: 'fas fa-helicopter',
    title: 'Aerial Solutions',
    description: 'Our meticulous approach and dedication to innovation allow us to leverage cutting-edge technology to capture data from a unique and privileged perspective. Whether through drones equipped with high-resolution cameras or specialized aircraft, our expert aerial solutions team works diligently to provide detailed and accurate imagery that feeds our Revit modeling processes with invaluable information.'
  },
  {
    id: 13,
    icon: 'fas fa-vr-cardboard',
    title: 'Virtual Immersions',
    description: 'We offer virtual immersion services that transform the design experience. With an innovative approach and cutting-edge technology, we take Revit modeling to a new dimension, allowing our clients to explore and visualize their projects in an immersive and realistic way. Our virtual immersions offer an immersive experience that allows stakeholders to feel like they are actually inside the designed space, providing a deep understanding and full appreciation of the proposed design. At DMS, we are committed to creating experiences that transcend expectations, merging art, technology, and vision to deliver extraordinary results that inspire and captivate.'
  },
]

export default servicesData;
