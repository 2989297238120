import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import img1 from '../assets/images/LOGO PNG 2-01.png';

export default function AppAbout(){
  return(
    <section id="about" className='block about-block'>
       <Container fluid>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6} style={{marginTop: '40px'}}>
            <p>
Welcome to DMS BIM STUDIO, where we merge our two decades of experience at the forefront of the AEC industry and BIM methodology to offer top-quality services. We are proud to work on commercial, residential, and industrial projects, committed to excellence in every step.
</p>

<p>
Our passionate and BIM technology-expert team provides customized and innovative solutions that exceed expectations. We specialize in high-end projects, where precision, elegance, and functionality come together to create inspiring spaces.
</p>

<p>
At DMS BIM STUDIO, we value strong relationships with our clients, collaborating closely to achieve their dreams. Our reputation for excellence and professionalism precedes us, reflected in a wide range of national projects.
</p>

<p>
We do not only create virtual constructions, but experiences that transform dreams into reality. Join us on this exciting journey towards the future of AEC, where every line drawn is a step towards excellence.
</p>
          </Col>
        </Row>
       </Container>
    </section>
  )
}
