import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typewriter from "typewriter-effect";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import servicesData from '../assets/services/services.Data.js';

const AppServices = () => {
  const [openStates, setOpenStates] = useState({});

  const toggleCollapse = (id) => {
    setOpenStates(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <Row>
          <Col sm={12} className="text-center" style={{marginBottom: '120px'}}>
            <Typewriter
              options={{
                strings: ["BIM SERVICES"],
                autoStart: true,
                loop: true,
                deleteSpeed: 20,
                wrapperClassName: "custom-typewriter",
                cursorClassName: "custom-cursor",
                delay: 50,
                stringClassName: "custom-string",
              }}
              className="custom-typewriter"
            />
          </Col>
          <Col sm={12}>
            <Row>
              {servicesData.map((service) => (
                <Col sm={6} key={service.id} className="service-item">
                  <Button
                    onClick={() => toggleCollapse(service.id)}
                    aria-controls={`collapse-${service.id}`}
                    aria-expanded={openStates[service.id]}
                    variant="link"
                    style={{ textDecoration: "none" }}
                    className="button-servicios"
                  >
                    <span>
                      <i className={service.icon} style={{ fontSize: "25px" }}></i>
                      <h3 style={{ display: "inline-block", marginLeft: "10px"}}>
                        {service.title}
                      </h3>
                    </span>
                  </Button>
                  <Collapse in={openStates[service.id]}>
                    <div id={`collapse-${service.id}`}>
                      <p style={{ textAlign: "left", fontFamily: 'Garet-Book' }}>{service.description}</p>
                    </div>
                  </Collapse>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AppServices;
