import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Headroom from 'react-headroom';

import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about';
import AppServices from './components/services';
import AppContact from './components/contact';
import AppFoter from './components/footer';
import AppContactSocials from './components/contactsocials';
import AppFormContact from './components/form_contact';

function App() {
  return (
    <div className="App">
      <header id="header">
        <Headroom>
          <AppHeader/>
        </Headroom>
      </header>
      <main>
        <AppHero/>
        <AppAbout/>
        <AppServices/>
        <AppContact/>
        <AppFormContact/>
        <AppContactSocials/>
      </main>
      <footer id='footer'>
        <AppFoter/>
      </footer>
    </div>
  );
}

export default App
